body {
  min-height: 100vh;
  font-family: 'Lato', sans-serif !important;
  font-size: 0.8rem !important;
}

.kingkong::before {
  content: '';
  position: fixed;
  top: -10px;
  left: 0;
  width: 100%;
  height: 10px;
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.8);
  z-index: 100;
}

label {
  font-weight: bold;
}

ul li {
  font-family: 'Lato', sans-serif !important;
}

a {
  text-decoration-color: #6187ac;
  text-decoration-thickness: 0.165em;
  text-underline-offset: 1.8px;
  color: #ffffff;
}

a:hover {
  color: #808080;
}

.af_code_pagination {
  display: inline-block;
}

.af_code_pagination a {
  color: black;
  float: left;
  padding: 4px 8px;
  text-decoration: none;
}

.af_code_pagination a.active {
  background-color: #2b5b5f;
  color: white;
}

.af_code_pagination a:hover:not(.active) {
  background-color: #6187ac;
}

td,
th {
  font-size: 0.8rem !important;
  font-family: Lato !important;
  color: #333;
}

h5 {
  font-weight: 400;
}

.money,
.number {
  text-align: right !important;
}

.form-control {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.previous td {
  display: normal;
  /* height: 0px; */
}

.form-control:focus {
  border-color: #e3dfd9;
  box-shadow: 0 0 0 0.2rem rgba(227, 223, 217, 0.9);
}

.form-select:focus {
  border-color: #e3dfd9;
  box-shadow: 0 0 0 0.2rem rgba(227, 223, 217, 0.9);
}

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.brand-warning {
  background-color: red !important;
}

/* Table Styles */

.table-wrapper {
  position: relative;
  overflow: auto;
  background: #3b454b;
  margin: 0 auto;
  display: block !important;
}

.fl-table {
  border-radius: 5px;
  font-size: 12px;
  font-weight: normal;
  border: none;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  background-color: white;
}

.fl-table td,
.fl-table th {
  text-align: left;
  padding: 8px;
}

.fl-table td {
  /* border-right: 1px solid #f8f8f8; */
  font-size: 12px;
  text-align: left;
}

.fl-table thead th {
  color: #ffffff;
  background: #142e3d;
  padding-top: 15px;
  padding-bottom: 15px;
}

.fl-table thead th:nth-child(odd) {
  color: #ffffff;
  background: #142e3d;
}

.fl-table tr:nth-child(even) {
  background: #f8f8f8;
}

.fl-table tr.available:nth-child(even) {
  background: #bdddf4;
}

.fl-table tr.available:nth-child(odd) {
  background: #cde3f2;
}

.not-number {
  text-align: left !important;
}

.Number {
  text-align: right !important;
}

/* Responsive */

/* @media (max-width: 767px) {
        .fl-table {
            display: block;
            width: 100%;
        }

        .table-wrapper:before {
            content: "Please use landscape mode on your phone and scroll horizontally >";
            display: block;
            text-align: left;
            font-size: 11px;
            color: white;
            padding: 0 0 10px;
        }

        .fl-table thead,
        .fl-table tbody,
        .fl-table thead th {
            display: block;
        }

        .fl-table thead th:last-child {
            border-bottom: none;
        }

        .fl-table thead {
            float: left;
        }

        .fl-table tbody {
            width: auto;
            position: relative;
            overflow-x: auto;
        }

        .fl-table td,
        .fl-table th {
            padding: 20px .625em .625em .625em;
            height: 60px;
            vertical-align: middle;
            box-sizing: border-box;
            overflow-x: hidden;
            overflow-y: auto;
            width: 120px;
            font-size: 13px;
            text-overflow: ellipsis;
        }

        .fl-table thead th {
            text-align: left;
            border-bottom: 1px solid #f7f7f9;
        }

        .fl-table tbody tr {
            display: table-cell;
        }

        .fl-table tbody tr:nth-child(odd) {
            background: none;
        }

        .fl-table tr:nth-child(even) {
            background: transparent;
        }

        .fl-table tr td:nth-child(odd) {
            background: #F8F8F8;
            border-right: 1px solid #E6E4E4;
        }

        .fl-table tr td:nth-child(even) {
            border-right: 1px solid #E6E4E4;
        }

        .fl-table tbody td {
            display: block;
            text-align: center;
        }
    } */

.footer {
  background: #142e3d;
  color: white;
}

.footer .links ul {
  list-style-type: none;
}

.footer .links li a {
  color: white;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

.footer .links li a:hover {
  text-decoration: none;
  color: #4180cb;
}

.footer .about-company i {
  font-size: 25px;
}

.footer .about-company a {
  color: white;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

.footer .about-company a:hover {
  color: #4180cb;
}

.footer .location i {
  font-size: 18px;
}

.footer .copyright p {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.btn-info {
  color: #fff;
  background-color: #2b5b5f;
  border-color: #2b5b5f;
}

.btn-info:hover {
  color: #fff;
  background-color: #142e3d;
  border-color: #2b5b5f;
}

.btn-check:focus + .btn-info,
.btn-info:focus {
  color: #fff;
  background-color: #538d77;
  border-color: #25cff2;
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}

.btn-check:checked + .btn-info,
.btn-check:active + .btn-info,
.btn-info:active,
.btn-info.active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #538d77;
  border-color: #25cff2;
}

.btn-check:checked + .btn-info:focus,
.btn-check:active + .btn-info:focus,
.btn-info:active:focus,
.btn-info.active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}

.btn-info:disabled,
.btn-info.disabled {
  color: #000;
  background-color: #2b5b5f;
  border-color: #2b5b5f;
}

.form-control-fullwidth {
  width: 100%;
}

.FOSBSDatePickerFullWidth,
.FOSBSDatePickerFullWidth > div.react-datepicker-wrapper,
.FOSBSDatePickerFullWidth
  > div
  > div.react-datepicker__input-container
  .FOSBSDatePickerFullWidth
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
}

.FOSBSRangeDatePickerWidth > div.react-datepicker-wrapper {
  width: 80%;
  padding-left: 0.5rem;
}

.north-sea-spot-caption {
  font-size: 16px;
  text-transform: uppercase;
  color: #e87e04;
  padding: 5px 0;
  margin-bottom: 5px;
  font-weight: bold;
}

.react-fine-uploader-gallery-file-input-content svg {
  vertical-align: inherit;
}

.react-fine-uploader-gallery-file-input-container {
  box-sizing: content-box;
}

.react-fine-uploader-gallery-preview-button {
  background: transparent;
  border: 0;
  position: absolute;
  left: -18px;
  top: -12px;
}

.react-fine-uploader-gallery-upload-success {
  -webkit-animation: cssHideAnimation 5s forwards;
  animation: cssHideAnimation 5s forwards;
}

@keyframes cssHideAnimation {
  0% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes cssHideAnimation {
  0% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.navbar-menuitem-active {
  font-weight: bold;
  color: darkturquoise !important;
  text-decoration-color: darkturquoise !important;
}

p.form-validation-error-message {
  color: #bf1650;
  font-weight: bold;
  margin-top: 5px;
}

p.form-validation-error-message::before {
  display: inline;
  content: '⚠ ';
}
/* media query for small devices */
@media only screen and (max-width: 768px) {
  .sidebar-column {
    flex: 0;
    width: 100%;
  }
  .main-column {
    width: 100%;
  }
}
